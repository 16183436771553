import React, { useState, useEffect, useRef } from 'react';
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Member from "../components/teamMember"

const TeamMembers = ({ data: { teamMembers }, location }) => {
  const [displayCount, setDisplayCount] = useState(8);
  const [shuffledOrder, setShuffledOrder] = useState([]);
  const [showMembers, setShowMembers] = useState([]);
  const lastMemberRef = useRef(null);

  // Function to shuffle the array
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  useEffect(() => {
    if (teamMembers && teamMembers.edges) {
      setShuffledOrder(shuffleArray(teamMembers.edges));
    }
  }, [teamMembers]);

  useEffect(() => {
    // Show the first 'displayCount' members initially
    setShowMembers(shuffledOrder.slice(0, displayCount));
  }, [shuffledOrder, displayCount]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // If the last member is intersecting, load more team members
          handleLoadMore();
        }
      });
    });

    if (lastMemberRef.current) {
      observer.observe(lastMemberRef.current);
    }

    return () => {
      if (lastMemberRef.current) {
        observer.unobserve(lastMemberRef.current);
      }
    };
  }, [lastMemberRef, showMembers]);

  const handleLoadMore = () => {
    // Increase the display count to show more members
    setDisplayCount(displayCount + 8);
  };

  return (
    <>
        <SEO
            titleOverride={"Who We Are"}
            descriptionOverride={"We're a results-driven design, marketing and brand agency providing digital transformation for our clients."}
            pathnameOverride={location.pathname}
        />

        <section className="mb-12">
            <div className="container">
                <div className="w-full mx-auto md:w-10/12">
                <div className="w-full mx-auto text-center md:w-9/12 lg:w-7/12">
                    <span className="block h1">Meet The Team</span>
                    <div className="content content--reset">Meet our award winning digital marketing team who collaborate with you, our clients, and each other to provide expert marketing solutions and deliver superb results. Highly skilled with years of invaluable experience in SEO, PPC, web design, marketing, branding, social media and more, we pride ourselves in being your partner in digital.</div>
                </div>
                </div>
            </div>
        </section>

        <section className="mb-12 lg:mb-24 overflow-hidden">
          <div className="container">
            <div className="w-full mx-auto md:w-10/12">
              <div className="flex flex-wrap md:-mx-1 justify-center">
              {showMembers.map(({ node }, index) => {
                if (index === showMembers.length - 1) {
                  // If it's the last member, attach the ref
                  return (
                    <Member
                      key={index}
                      name={node.teamMemberName}
                      job={node.jobTitle}
                      photo={node.teamMemberPhoto.fluid}
                      transitionDelay={index * 120} // Example delay, adjust as needed
                      ref={lastMemberRef}
                    />
                  );
                } else {
                  return (
                    <Member
                      key={index}
                      name={node.teamMemberName}
                      job={node.jobTitle}
                      photo={node.teamMemberPhoto.fluid}
                      transitionDelay={index * 120} // Example delay, adjust as needed
                    />
                  );
                }
              })}

                <div className="container w-full flex justify-center">
                  {displayCount < shuffledOrder.length && (
                    <button className="text-base capitalize btn btn--primary mt-4" onClick={handleLoadMore}>Load More Team Members</button>
                  )}
                </div>

              </div>
            </div>
          </div>
        </section>
    </>
  )
}

export default TeamMembers;

export const query = graphql`
  query TeamMemberQuery {
    teamMembers: allDatoCmsTeamMember {
      edges {
        node {
          jobTitle
          teamMemberName
          teamMemberPhoto {
            fluid(
              maxWidth: 720
              imgixParams: {h: "500", w: "500", fit: "crop", crop: "faces, edges"}) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;
