import React, { useState, useEffect } from 'react';
import Img from "gatsby-image";

const Member = ({ name, job, photo, transitionDelay }) => {
  return (    
    <div
      className="w-full max-w-md md:w-2/4 lg:w-1/3 xl:w-1/3 md:px-4 mb-4 member-item item fade-in"
      style={{ '--animation-delay': `${transitionDelay}ms` }} // Set the animation delay dynamically
    >
      <div className="bg-secondary-dark text-white p-10 pb-8 text-center h-full rounded-md">
        <div className="aspect-w-1 aspect-h-1 mb-6">
          <Img fluid={photo} className="rounded-full object-cover object-center w-full h-full max-w-sm mx-auto member-image" />
        </div>
        <p className="text-2xl mb-1 font-bold h3">{name}</p>
        <p>{job}</p>
      </div>
    </div>
  );
};

export default Member;
